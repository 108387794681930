<template>
  <div>
    <div class="bg">
      <div class="contain">
        <div class="contain_top">
          <div class="left">
            <img :src="info.avatar?$imgURL+info.avatar:icon52" alt="" class="img1" />
            <div>
              {{ info.username
              }}<img
                src="@/assets/image/icon43.png"
                alt=""
                class="img2"
                v-if="info.is_vip == 1"
              />
            </div>
          </div>
          <div class="right">
            <div class="num">
              <span>文档</span><span>{{ info.doc_count || 0 }}</span>
            </div>
            <div class="line"></div>
            <div class="num">
              <span>收藏</span><span>{{ info.favorite_me_count || 0 }}</span>
            </div>
            <div class="line"></div>
            <div class="num">
              <span>余额</span><span>{{ info.price || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="contain_line"></div>
        <div class="contain_input">
          <div class="search">
            <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="wd"
            >
            </el-input>
          </div>
          <div class="time">
            <el-date-picker
              v-model="created_at"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              @change="changeTime"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
          <div class="searchBtn" @click="search">搜索</div>
        </div>
        <div class="table">
          <el-table
            :data="docList"
            style="width: 100%"
            header-cell-class-name="tabe_header"
          >
            <el-table-column label="名称" width="240">
              <template slot-scope="scope">
                <div class="box1">
                  <img
                    v-if="scope.row.ext == '.doc' || scope.row.ext == '.docx'"
                    src="@/assets/image/icon113.png"
                    alt=""
                    class="contain1_title_img1"
                  />
                  <img
                    v-else-if="scope.row.ext == '.pdf'"
                    src="@/assets/image/icon118.png"
                    alt=""
                    class="contain1_title_img1"
                  />
                  <img
                    v-else-if="scope.row.ext == '.ppt'"
                    src="@/assets/image/icon116.png"
                    alt=""
                    class="contain1_title_img1"
                  />
                  <img
                    v-else-if="scope.row.ext == '.xls'"
                    src="@/assets/image/icon114.png"
                    alt=""
                    class="contain1_title_img1"
                  />
                  <img
                    v-else-if="scope.row.ext == '.txt'"
                    src="@/assets/image/icon115.png"
                    alt=""
                    class="contain1_title_img1"
                  />
                  <img
                    v-else
                    src="@/assets/image/icon117.png"
                    alt=""
                    class="contain1_title_img1"
                  />
                  <div style="color:dodgerblue;cursor: pointer;" @click="goDetail(scope.row)">{{ scope.row.title }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="评分" width="140">
              <template slot-scope="scope">
                <el-rate
                  v-model="scope.row.score"
                  disabled
                  text-color="#ff9900"
                  score-template="{value}"
                >
                </el-rate>
              </template>
            </el-table-column>
            <el-table-column label="浏览">
              <template slot-scope="scope">
                <div>{{ scope.row.view_count || 0 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="下载">
              <template slot-scope="scope">
                <div>{{ scope.row.download_count || 0 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="收藏">
              <template slot-scope="scope">{{
                scope.row.favorite_count || 0
              }}</template>
            </el-table-column>
            <el-table-column label="价格">
              <template slot-scope="scope">{{
                scope.row.price || "0"
              }}</template>
            </el-table-column>
            <el-table-column label="大小">
              <template slot-scope="scope">{{
                formatBytes(scope.row.size)
              }}</template>
            </el-table-column>
            <el-table-column label="上传时间">
              <template slot-scope="scope">
                <el-tooltip
                  :content="formatDatetime(scope.row.created_at)"
                  placement="top"
                >
                  <span>{{ formatRelativeTime(scope.row.created_at) }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <div class="btn_icon">
                  <img
                    src="@/assets/image/icon64.png"
                    alt=""
                    @click="edit(scope.row)"
                    class="del"
                  />
                  <img
                    src="@/assets/image/icon56.png"
                    alt=""
                    @click="delToast(scope.row)"
                    class="del"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="height: 800px" v-if="docList.length <= 5"></div>
        <div class="pagination_contain">
          <div>共{{ total || 0 }}项数据</div>
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="page"
            :page-size="size"
            :total="total"
            @current-change="checkoutDocList"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="20%">
      <template slot="title">
        <div class="dialog_header" style="display: flex; align-items: center">
          <img
            style="width: 25px; height: 25px; margin-right: 6px"
            src="@/assets/image/icon65.png"
            alt=""
          />提示
        </div>
      </template>
      <span>您确定要删除《{{ currentDoc ? currentDoc.title : "" }}》吗？</span>
      <span slot="footer" style="display: flex; justify-content: flex-end">
        <div
          style="
            width: 130px;
            height: 45px;
            background: #f6f7f8;
            border-radius: 4px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: #666666;
            line-height: 45px;
            margin-right: 10px;
            cursor: pointer;
          "
          @click="dialogVisible = false"
        >
          取消
        </div>
        <div
          style="
            width: 130px;
            height: 45px;
            background: linear-gradient(279deg, #23c48c 15%, #33da61 88%);
            border-radius: 4px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 45px;
            cursor: pointer;
          "
          @click="deleteDoc"
        >
          确定
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑文档"
      :visible.sync="isEdit"
      custom-class="editToast_contain"
      width="50%"
    >
      <div class="editToast">
        <div class="editToast_line"></div>
        <div class="editToast_input1">
          <div class="editToast_input1_tip">
            <span>*</span><span>名称</span>
          </div>
          <el-input
            placeholder="请输入文档名称"
            v-model="currentDoc.title"
          ></el-input>
        </div>
        <div class="editToast_input2">
          <div class="editToast_input2_left">
            <div class="editToast_input2_tip">
              <span>*</span><span>分类</span>
            </div>
            <el-cascader
              style="width: 670px"
              v-model="currentDoc.category_id"
              :options="categoryTrees"
              :filterable="true"
              :props="{
                checkStrictly: true,
                expandTrigger: 'hover',
                label: 'title',
                value: 'id',
              }"
              clearable
              placeholder="请选择文档分类"
              @change="handleChange"
            ></el-cascader>
          </div>
        </div>
        <div class="editToast_input2" style="margin-top: 20px;">
          <div>价格</div>
          <el-input-number
            v-model="currentDoc.price"
            :min="0"
            :precision="2"
            @change="handleChange"
          ></el-input-number>
        </div>
        <!-- <div class="kg">
          <div>VIP文档</div>

          <el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div> -->
        <div class="editToast_input3">
          <div>关键词</div>
          <el-input
            placeholder="请输入文档关键字,多个关键字用英文逗号分隔"
            v-model="currentDoc.keywords"
          ></el-input>
        </div>
        <div class="editToast_input3">
          <div>摘要</div>
          <el-input
            placeholder="请输入文档摘要"
            v-model="currentDoc.description"
          ></el-input>
        </div>
        <div class="editToast_btn" @click="submit()">提交</div>
      </div>
    </el-dialog>
  </div>
</template>
    
<script>
import { postJSON, PUT } from "../../../utils/request";
import { get } from "/utils/request";
import {
  formatBytes,
  formatDatetime,
  formatRelativeTime,
  categoryToTrees,
} from "/utils/utils";
import icon52 from "@/assets/image/icon52.png";

export default {
  data() {
    return {
      icon52: icon52,
      dialogVisible: false,
      isEdit: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value: "",
      value2: "",
      list1: [
        {
          id: 1,
          name: "文档名称文档名称文档名称文档名称文档名称",
          score: 4,
          views: "123",
          donloads_num: "123",
          collect_num: "123",
          price: "2342",
          size: "190.0mb",
          time: "2023.12.12 12:22",
        },
      ],
      currentDoc: {},
      options: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout 布局",
                },
                {
                  value: "color",
                  label: "Color 色彩",
                },
                {
                  value: "typography",
                  label: "Typography 字体",
                },
                {
                  value: "icon",
                  label: "Icon 图标",
                },
                {
                  value: "button",
                  label: "Button 按钮",
                },
              ],
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio 单选框",
                },
                {
                  value: "checkbox",
                  label: "Checkbox 多选框",
                },
                {
                  value: "input",
                  label: "Input 输入框",
                },
                {
                  value: "input-number",
                  label: "InputNumber 计数器",
                },
                {
                  value: "select",
                  label: "Select 选择器",
                },
                {
                  value: "cascader",
                  label: "Cascader 级联选择器",
                },
                {
                  value: "switch",
                  label: "Switch 开关",
                },
                {
                  value: "slider",
                  label: "Slider 滑块",
                },
                {
                  value: "time-picker",
                  label: "TimePicker 时间选择器",
                },
                {
                  value: "date-picker",
                  label: "DatePicker 日期选择器",
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker 日期时间选择器",
                },
                {
                  value: "upload",
                  label: "Upload 上传",
                },
                {
                  value: "rate",
                  label: "Rate 评分",
                },
                {
                  value: "form",
                  label: "Form 表单",
                },
              ],
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table 表格",
                },
                {
                  value: "tag",
                  label: "Tag 标签",
                },
                {
                  value: "progress",
                  label: "Progress 进度条",
                },
                {
                  value: "tree",
                  label: "Tree 树形控件",
                },
                {
                  value: "pagination",
                  label: "Pagination 分页",
                },
                {
                  value: "badge",
                  label: "Badge 标记",
                },
              ],
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert 警告",
                },
                {
                  value: "loading",
                  label: "Loading 加载",
                },
                {
                  value: "message",
                  label: "Message 消息提示",
                },
                {
                  value: "message-box",
                  label: "MessageBox 弹框",
                },
                {
                  value: "notification",
                  label: "Notification 通知",
                },
              ],
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu 导航菜单",
                },
                {
                  value: "tabs",
                  label: "Tabs 标签页",
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb 面包屑",
                },
                {
                  value: "dropdown",
                  label: "Dropdown 下拉菜单",
                },
                {
                  value: "steps",
                  label: "Steps 步骤条",
                },
              ],
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog 对话框",
                },
                {
                  value: "tooltip",
                  label: "Tooltip 文字提示",
                },
                {
                  value: "popover",
                  label: "Popover 弹出框",
                },
                {
                  value: "card",
                  label: "Card 卡片",
                },
                {
                  value: "carousel",
                  label: "Carousel 走马灯",
                },
                {
                  value: "collapse",
                  label: "Collapse 折叠面板",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      info: {},
      docList: [],
      page: 1,
      size: 20,
      total: 0,
      wd: "",
      created_at: "",
      isSearch: false,
      categoryTrees: [],
    };
  },
  mounted() {
    this.getUserInfo();
    // this.getDocList();
  },
  watch: {},
  methods: {
    changeTime() {
      this.$forceUpdate();
    },
    goDetail(rew) {
      this.$router.push(`docDetail?id=${rew.id}`);
    },
    delToast(item) {
      this.currentDoc = item;
      this.dialogVisible = true;
    },
    deleteDoc() {
      postJSON("/document/del", {
        id: this.currentDoc.id,
      }).then(() => {
        // if (res.code !== 200) {
        //   // this.$message({
        //   //   message: res.message,
        //   //   type: "error",
        //   // });
        //   return;
        // }
        // if (Object.getOwnPropertyNames(res).length === 0) {
        this.dialogVisible = false;
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.page = 1;
        this.docList = [];
        this.getUserInfo();
        this.getDocList(1, Number(this.info.id));
        // }
      });
    },
    edit(item) {
      this.getDocument(item);
      this.getCategoryList();
      this.isEdit = true;
    },
    getDocument(item) {
      get("/document", { id: item.id, with_author: true }).then((res) => {
        this.currentDoc = res;
      });
    },
    getCategoryList() {
      get("/category/list", {}).then((res) => {
        this.categoryTrees = categoryToTrees(res.category);
      });
    },
    submit() {
      PUT("/document", this.currentDoc).then((res) => {
        if (Object.getOwnPropertyNames(res).length === 0) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getUserInfo();
          this.isEdit = false;
        } else {
          this.$message.error("修改失败" + res.message);
        }
      });
    },
    handleChange() {},
    async getUserInfo() {
      // /api/v1/user
      let res = await get("/info/user", {});
      this.info = res.data;
       this.getDocList(1, res.data.id);
    },
    getDocList(val, user_id) {
      // let that = this
      if (val) {
        this.page = val;
      }
      // /api/v1/document/list?page=1&size=20&user_id=5
      this.isSearch = false;
      get(
        `/document/list?page=${this.page}&size=20&user_id=${user_id}`,
        {}
      ).then((res) => {
        this.total = res.total;
        res.document.forEach((item) => {
          item["score"] = item.score / 100;
        });
        this.docList = res.document;
      });
    },
    getSearchDocList(val) {
      if (this.val) {
        this.page = val;
      }
      get("/document/search", {
        page: this.page,
        size: 20,
        user_id: this.info.id,
        created_at: this.created_at ? this.created_at : [],
        wd: this.wd,
      }).then((res) => {
        if (!res.document) {
          this.docList = [];
          return;
        }
        this.total = res.total;
        res.document.forEach((item) => {
          item["score"] = item.score / 100;
        });
        this.docList = res.document;
      });
    },
    formatBytes,
    formatDatetime,
    formatRelativeTime,
    search() {
      this.page = 1;
      this.docList = [];
      console.log("isArray", Array.isArray(this.created_at));

      if ((this.created_at && this.created_at[0]) || this.wd) {
        console.log(this.created_at, this.wd);
        this.isSearch = true;
        this.getSearchDocList();
        return;
      }
      this.isSearch = false;
      this.getDocList(1, this.info.id);
    },
    checkoutDocList(val) {
      if (this.isSearch) {
        this.getSearchDocList(val);
      } else {
        this.getDocList(val, this.info.id);
      }
    },
  },
};
</script>
    
  <style lang="scss" scoped>
.bg {
  width: 100%;
  //   height: 620px;
  background: url("../../assets/image/bg.png");
  background-size: 100% 622px;
  background-repeat: no-repeat;
  padding: 23px 0 0;
  box-sizing: border-box;
  .contain {
    width: 1400px;
    min-height: 1054px;
    background: #ffffff;
    border-radius: 2px;
    margin: 0 auto 41px;
    padding: 30px 20px;
    box-sizing: border-box;
    .contain_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .left {
        display: flex;
        align-items: center;
        .img1 {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-right: 10px;
        }
        div {
          display: flex;
          flex-direction: column;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #333333;
          .img2 {
            width: 58px;
            height: 17px;
            margin-top: 4px;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        .num {
          display: flex;
          flex-direction: column;
          align-items: center;
          span:nth-child(1) {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #666666;
            margin-bottom: 10px;
          }
          span:nth-child(2) {
            font-size: 20px;
            font-family: DIN Alternate, DIN Alternate-Bold;
            font-weight: 700;
            color: #333333;
          }
        }
        .line {
          width: 1px;
          height: 37px;
          opacity: 0.3;
          background: #afafaf;
          margin: 0 30px;
        }
      }
    }
  }
  .contain_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  .contain_input {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .search {
      width: 276px;
      margin-right: 15px;
    }
    .searchBtn {
      width: 130px;
      height: 40px;
      background: linear-gradient(279deg, #23c48c 15%, #33da61 88%);
      border-radius: 4px;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 40px;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  ::v-deep .tabe_header {
    background: #f6f7f8 !important;
  }
  .box1 {
    display: flex;
    align-items: center;
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    div:nth-child(2) {
      //   width: 240px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .btn_icon {
    display: flex;
    align-items: center;
    .del {
      width: 22px;
      height: 22px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    height: 60px;
    background: #f7f7f7;
    border-radius: 2px;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>
<style lang="scss">
.el-dialog__body {
  padding: 10px 20px 20px;
  box-sizing: border-box;
}
.editToast {
  .editToast_line {
    height: 1px;
    background: #f4f4f4;
    margin-bottom: 20px;
  }
  .editToast_input1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .editToast_input1_tip {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      letter-spacing: 0.7px;
      margin-bottom: 15px;

      span:nth-child(1) {
        color: #ff0000;
      }
      span:nth-child(2) {
        color: #333333;
      }
    }
  }

  .editToast_input2 {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .editToast_input2_left {
      width: 670px;
      .editToast_input2_tip {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        letter-spacing: 0.7px;
        margin-bottom: 15px;

        span:nth-child(1) {
          color: #ff0000;
        }
        span:nth-child(2) {
          color: #333333;
        }
      }
    }
    .editToast_input2_right {
      display: felx;
      flex-direction: column;
      div {
        margin-bottom: 15px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        letter-spacing: 0.7px;
      }
    }
  }
  .kg {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
    letter-spacing: 0.7px;
    margin-bottom: 20px;
    div {
      margin-bottom: 15px;
    }
  }
  .editToast_input3 {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
    letter-spacing: 0.7px;
    div {
      margin-bottom: 15px;
    }
  }
  .editToast_btn {
    // width: 860px;
    height: 65px;
    background: linear-gradient(90deg, #23c48c, #33da61);
    border-radius: 6px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0.9px;
    line-height: 65px;
    margin-top: 30px;
    cursor: pointer;
  }
}
</style>